<template>
    <div class="question-result">
        <div class="result-item">
            <span class="answer-label">我的答案：</span>

            <!-- 未作答 -->
            <span v-if="!question.userAnswerText" class="answer-content-error">未作答</span>
            <!-- 答对 -->
            <span v-else-if="question.userAnswerState" class="answer-content" v-html="userAnswerText" />
            <!-- 答错 -->
            <span
                v-else-if="!question.userAnswerState && question.userScore === 0"
                class="answer-content-error"
                v-html="userAnswerText"
            >
            </span>
            <!-- 填空题 -->
            <template v-if="question.userScore !== 0 && question.userScore !== question.score">
                <span
                    :class="item.state ? 'answer-content' : 'answer-content-error'"
                    v-for="(item, index) in blankAnswerList"
                    :key="index"
                >
                    {{ item.val }}
                </span>
            </template>
        </div>
        <div class="result-item" v-if="examVip">
            <span class="answer-label">正确答案：</span>
            <Markdown class="answer-content" :text="answer" />
        </div>
        <div class="result-item" v-if="examVip">
            <span class="answer-label">试题解析：</span>
            <Markdown class="analysis" :text="question.analysisContent" />
        </div>
    </div>
</template>
<script>
import Markdown from '@/components/Markdown'

export default {
    props: ['question', 'multipleType', 'examVip'],
    created() {
        this.setAnswer()
    },
    components: { Markdown },
    data() {
        return {
            blankAnswerList: [],
            userAnswerList: [],
            answerList: [],
        }
    },
    computed: {
        userAnswerText: function () {
            if (this.multipleType.includes(this.question.type)) {
                const list = this.question.userAnswerText.split(',')
                const val = []
                list.forEach((item) => {
                    if (!item) {
                        val.push('未作答')
                    } else {
                        val.push(item)
                    }
                })
                return val.join(',')
            }
            return this.question.userAnswerText
        },
        answer: function () {
            if (this.multipleType.includes(this.question.type)) {
                return this.question.answer.split(',').join(', ')
            }
            return this.question.answer
        },
    },
    methods: {
        setAnswer() {
            if (this.question.userScore !== 0 && this.question.userScore !== this.question.score) {
                const userAnswerList = this.question.userAnswerText.split(',')
                const answerList = this.question.answer ? this.question.answer.split(',') : []
                const blankAnswerList = []
                let answerState = false
                userAnswerList.forEach((item, index) => {
                    if (this.question.type === 'ORDER_BLANK') {
                        answerState = answerList[index] === item
                    } else if (this.question.type === 'DISORDER_BLANK') {
                        answerState = answerList.includes(item)
                    }
                    const val = index === userAnswerList.length - 1 ? item : item + ','
                    blankAnswerList.push({
                        state: answerState,
                        val: val,
                    })
                })
                this.blankAnswerList = blankAnswerList
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/paper.scss';

.question-result {
    margin-bottom: 12px;
}
.result-item {
    font-size: 14px;
    padding: 6px 10px 0 12px;
    display: flex;
    .answer-label {
        flex-shrink: 0;
    }
    .answer-content {
        color: $paper-correct-color;
        white-space: pre-wrap;
        letter-spacing: 0.5px;
        word-spacing: 2px;
        line-height: 1.4em;
        ::v-deep p {
            margin-bottom: 8px;
        }
    }
    .answer-content-error {
        color: $paper-error-color;
        font-weight: 700;
    }
    .analysis {
        line-height: 1.5em;
    }
    span {
        line-height: 1.4em;
        vertical-align: top;
    }
}
</style>
