<template>
    <div id="paperResult">
        <Header />
        <PaperResultPC class="paper-result" v-if="deviceInfo.pc || deviceInfo.tabletPc" />
        <div class="paper-result" v-else></div>
        <Fotter />
        <Watermark />

        <PcDialog v-if="!(deviceInfo.pc || deviceInfo.tabletPc)" />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import Watermark from '@/components/Watermark'
import PcDialog from '@/components/paper/PcDialog'
import PaperResultPC from './PaperResultPC.vue'
import { mapGetters } from 'vuex'

export default {
    components: { Header, Fotter, PaperResultPC, PcDialog, Watermark },
    computed: { ...mapGetters(['deviceInfo']) },
}
</script>
<style lang="scss" scoped>
#paperResult {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .paper-result {
        flex-grow: 1;
    }
}
</style>
