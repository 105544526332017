<template>
    <div class="raperResult">
        <div class="container" v-if="paperInfo && moduleQuestionList.length !== 0">
            <div class="question-container" ref="questionContainer">
                <div class="paper-title">{{ paperInfo.title }}</div>
                <div class="result-info">
                    <div class="score">{{ resultInfo.totalScore }}<span>分</span></div>
                    <div class="result-title">答题详情</div>
                    <div class="result-detail">
                        <div>
                            <div>正确数</div>
                            <div class="result-num correct-color">{{ resultInfo.correctNum }}</div>
                        </div>
                        <div>
                            <div>错误数</div>
                            <div class="result-num">{{ resultInfo.errorNum }}</div>
                        </div>
                        <div>
                            <div>未作答</div>
                            <div class="result-num">{{ resultInfo.unCompleteNum }}</div>
                        </div>
                        <div>
                            <div>正确率</div>
                            <div class="result-num correct-color">{{ resultInfo.correctScale }}%</div>
                        </div>
                    </div>
                </div>
                <div
                    :ref="`moduleItem${mItem.id}`"
                    class="module-item"
                    v-for="mItem in moduleQuestionList"
                    :key="mItem.id"
                >
                    <div class="module-title">
                        {{ mItem.title }}
                        <span v-if="mItem.score">(共计 {{ mItem.score }} 分)</span>
                    </div>
                    <Markdown class="module-subtitle" v-if="mItem.subtitle" :text="mItem.subtitle" />
                    <div class="module-container">
                        <div
                            :ref="`questionItem${item.id}`"
                            class="question-item"
                            v-for="item in mItem.questions"
                            :key="item.id"
                        >
                            <HeaderModule
                                :question="item"
                                :isResult="true"
                                :blankType="blankType"
                                :complexType="complexType"
                            />
                            <ChoiceModule :question="item" :isResult="true" />
                            <div class="question-multiple" v-if="complexType.includes(item.type)">
                                <div
                                    class="question-multiple-item"
                                    :ref="`questionItem${item.id}-${childItem.id}`"
                                    v-for="childItem in item.childQuestions"
                                    :key="childItem.id"
                                >
                                    <HeaderModule
                                        :question="childItem"
                                        :blankType="blankType"
                                        :complexType="complexType"
                                        :isResult="true"
                                    />
                                    <ChoiceModule :question="childItem" :isResult="true" />
                                    <AnswerAnalysis
                                        :examVip="examVip"
                                        :question="childItem"
                                        :multipleType="multipleType"
                                    />
                                </div>
                            </div>
                            <AnswerAnalysis
                                :examVip="examVip"
                                :multipleType="multipleType"
                                v-if="!complexType.includes(item.type)"
                                :question="item"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <QuestionCard
                :isResult="true"
                :examVip="examVip"
                :paperInfo="paperInfo"
                :complexType="complexType"
                :moduleQuestionList="moduleQuestionList"
                @module="onChangeModule"
                @question="onChangeQuestion"
            />
        </div>
    </div>
</template>
<script>
import QuestionCard from '@/components/paper/QuestionCard'
import HeaderModule from '@/components/paper/HeaderModule'
import ChoiceModule from '@/components/paper/ChoiceModule'
import AnswerAnalysis from '@/components/paper/AnswerAnalysis'
import Markdown from '@/components/Markdown'
import { getQuestionBlankConfig } from '@/utils/tools'
import { getPaperBlockQuestions, getPaperInfo, getLastCommitAnswer, getAnalysis } from '@/api/paperApi.js'

export default {
    name: 'paperResult',
    data() {
        return {
            examVip: true,
            paperInfo: null,
            resultInfo: null,
            moduleQuestionList: [],

            multipleType: ['SINGLE_CHOICE', 'MULTIPLE_CHOICE', 'JUDGMENT', 'ORDER_BLANK', 'DISORDER_BLANK'],
            blankType: ['ORDER_BLANK', 'DISORDER_BLANK'],
            complexType: ['LISTENING', 'EREADING_COMPREHENSION'],
        }
    },
    components: { QuestionCard, HeaderModule, ChoiceModule, AnswerAnalysis, Markdown },
    created() {
        this.paperNo = this.$route.params.paperNo
        this.getLastCommitAnswerApi()
    },
    methods: {
        onChangeModule(item) {
            const offsetTop = this.$refs[`moduleItem${item.id}`][0].offsetTop
            window.scrollTo(0, offsetTop)
        },
        onChangeQuestion(item, childItem) {
            let refName = `questionItem${item.id}`
            if (childItem) {
                refName += `-${childItem.id}`
            }
            const offsetTop = this.$refs[refName][0].offsetTop - 50
            window.scrollTo(0, offsetTop)
        },
        getLastCommitAnswerApi() {
            getLastCommitAnswer(this.paperNo).then((res) => {
                if (res.data.length !== 0) {
                    if (this.examVip) {
                        this.initQuestions(res.data)
                    } else {
                        this.initNoAnalysisPaper(res.data)
                    }
                } else {
                    this.$toast('暂无作答结果')
                    this.$router.back()
                }
            })
        },
        initQuestions(userAnswer) {
            Promise.all([
                getPaperInfo({ paperNo: this.paperNo }),
                getPaperBlockQuestions({ paperNo: this.paperNo }),
                getAnalysis(this.paperNo),
            ]).then((res) => {
                // 试卷信息
                document.title = res[0].data.title
                this.paperInfo = res[0].data

                // 先处理试题解析，并初始化用户作答
                const analysisMap = {}
                const userAnswerMap = {}
                res[2].data.forEach((item) => {
                    userAnswerMap[item.questionId] = {
                        answer: '',
                        score: 0,
                        state: false,
                    }
                    analysisMap[item.questionId] = item
                })

                // 用户作答结果
                let totalScore = 0
                let correctNum = 0
                let errorNum = 0
                userAnswer.forEach((item) => {
                    totalScore += item.score
                    if (item.state) {
                        correctNum += 1
                    }
                    if (!item.state && item.answer) {
                        errorNum += 1
                    }
                    userAnswerMap[item.questionId] = {
                        answer: item.answer || '',
                        score: item.score || 0,
                        state: item.state || false,
                    }
                })
                let unCompleteNum = res[2].data.length - correctNum - errorNum

                // 试题信息
                const { blankType, complexType, multipleType } = this
                let index = 1
                res[1].data.forEach((mItem) => {
                    mItem.questions.forEach((item) => {
                        // 设置序号
                        if (!complexType.includes(item.type)) {
                            item.questionIndex = index
                            index = index + 1
                        }
                        if (complexType.includes(item.type) && item.childQuestions) {
                            item.childQuestions.forEach((child) => {
                                // 设置序号
                                if (!complexType.includes(child.type)) {
                                    child.questionIndex = index
                                    index = index + 1
                                }
                                let answer = analysisMap[child.id]['answer']
                                let userAnswerText = userAnswerMap[child.id]['answer']
                                if (blankType.includes(child.type)) {
                                    child.userAnswer = new Array(child.childCnt)
                                    // TODO 填空题格式兼容
                                    if (child.content.indexOf('_____') > -1) {
                                        child.titleList = child.content.split('_____')
                                    } else {
                                        // 提取配置宽度
                                        child.titleList = child.content.split(/BLANK__\d+/)
                                        child.blankConfig = getQuestionBlankConfig(child.content)
                                    }
                                }
                                if (multipleType.includes(child.type)) {
                                    answer = analysisMap[child.id]['answer'].replace(/@@/g, ',')
                                    userAnswerText = userAnswerMap[child.id]['answer'].replace(/@@/g, ',')
                                }
                                child.answer = answer
                                child.analysisContent = analysisMap[child.id]['analysisContent']
                                child.userScore = userAnswerMap[child.id]['score']
                                child.userAnswerText = userAnswerText
                                child.userAnswerState = userAnswerMap[child.id]['state']
                            })
                        } else {
                            let answer = analysisMap[item.id]['answer']
                            let userAnswerText = userAnswerMap[item.id]['answer']
                            if (blankType.includes(item.type)) {
                                item.userAnswer = new Array(item.childCnt)
                                // TODO 填空题格式兼容
                                if (item.content.indexOf('_____') > -1) {
                                    item.titleList = item.content.split('_____')
                                } else {
                                    // 提取配置宽度
                                    item.titleList = item.content.split(/BLANK__\d+/)
                                    item.blankConfig = getQuestionBlankConfig(item.content)
                                }
                            }
                            if (multipleType.includes(item.type)) {
                                answer = analysisMap[item.id]['answer'].replace(/@@/g, ',')
                                userAnswerText = userAnswerMap[item.id]['answer'].replace(/@@/g, ',')
                            }
                            item.answer = answer
                            item.analysisContent = analysisMap[item.id]['analysisContent']
                            item.userScore = userAnswerMap[item.id]['score']
                            item.userAnswerText = userAnswerText
                            item.userAnswerState = userAnswerMap[item.id]['state']
                        }
                    })
                })
                this.resultInfo = {
                    totalScore,
                    correctNum,
                    errorNum,
                    unCompleteNum,
                    correctScale: parseInt((correctNum / userAnswer.length) * 1000) / 10,
                }
                this.moduleQuestionList = res[1].data
            })
        },
        initNoAnalysisPaper(userAnswer) {
            Promise.all([
                getPaperInfo({ paperNo: this.paperNo }),
                getPaperBlockQuestions({ paperNo: this.paperNo }),
            ]).then((res) => {
                document.title = res[0].data.title
                this.paperInfo = res[0].data
                // 用户作答结果
                const userAnswerMap = {}
                let totalScore = 0
                let correctNum = 0
                let errorNum = 0
                userAnswer.forEach((item) => {
                    totalScore += item.score
                    if (item.state) {
                        correctNum += 1
                    }
                    if (!item.state && item.answer) {
                        errorNum += 1
                    }
                    userAnswerMap[item.questionId] = {
                        answer: item.answer || '',
                        score: item.score || 0,
                        state: item.state || false,
                    }
                })
                let unCompleteNum = userAnswer.length - correctNum - errorNum

                // 试题信息
                const { blankType, complexType, multipleType } = this
                let index = 1
                res[1].data.forEach((mItem) => {
                    mItem.questions.forEach((item) => {
                        // 设置序号
                        if (!complexType.includes(item.type)) {
                            item.questionIndex = index
                            index = index + 1
                        }
                        if (complexType.includes(item.type) && item.childQuestions) {
                            item.childQuestions.forEach((child) => {
                                // 设置序号
                                if (!complexType.includes(child.type)) {
                                    child.questionIndex = index
                                    index = index + 1
                                }
                                let userAnswerText = userAnswerMap[child.id] ? userAnswerMap[child.id]['answer'] : ''
                                if (blankType.includes(child.type)) {
                                    child.userAnswer = new Array(child.childCnt)
                                    // TODO 填空题格式兼容
                                    if (child.content.indexOf('_____') > -1) {
                                        child.titleList = child.content.split('_____')
                                    } else {
                                        // 提取配置宽度
                                        child.titleList = child.content.split(/BLANK__\d+/)
                                        child.blankConfig = getQuestionBlankConfig(child.content)
                                    }
                                }
                                if (multipleType.includes(child.type)) {
                                    userAnswerText = userAnswerMap[child.id]
                                        ? userAnswerMap[child.id]['answer'].replace(/@@/g, ',')
                                        : []
                                }
                                child.answer = ''
                                child.analysisContent = ''
                                child.userScore = userAnswerMap[child.id] ? userAnswerMap[child.id]['score'] : 0
                                child.userAnswerText = userAnswerText
                                child.userAnswerState = userAnswerMap[child.id]
                                    ? userAnswerMap[child.id]['state']
                                    : false
                            })
                        } else {
                            let userAnswerText = userAnswerMap[item.id] ? userAnswerMap[item.id]['answer'] : ''
                            if (blankType.includes(item.type)) {
                                item.userAnswer = new Array(item.childCnt)
                                // TODO 填空题格式兼容
                                if (item.content.indexOf('_____') > -1) {
                                    item.titleList = item.content.split('_____')
                                } else {
                                    // 提取配置宽度
                                    item.titleList = item.content.split(/BLANK__\d+/)
                                    item.blankConfig = getQuestionBlankConfig(item.content)
                                }
                            }
                            if (multipleType.includes(item.type)) {
                                userAnswerText = userAnswerMap[item.id]
                                    ? userAnswerMap[item.id]['answer'].replace(/@@/g, ',')
                                    : []
                            }
                            item.answer = ''
                            item.analysisContent = ''
                            item.userScore = userAnswerMap[item.id] ? userAnswerMap[item.id]['score'] : 0
                            item.userAnswerText = userAnswerText
                            item.userAnswerState = userAnswerMap[item.id] ? userAnswerMap[item.id]['state'] : false
                        }
                    })
                })
                this.resultInfo = {
                    totalScore,
                    correctNum,
                    errorNum,
                    unCompleteNum,
                    correctScale: parseInt((correctNum / userAnswer.length) * 1000) / 10,
                }
                this.moduleQuestionList = res[1].data
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/paper.scss';

.raperResult {
    width: 100%;
    background: #f7f7f7;
    .container {
        width: $center-width;
        margin: 0 auto;
        padding: 24px 0;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 1000px) {
    .raperResult {
        .container {
            width: auto;
            padding: 24px 12px;
        }

        .question-container {
            padding: 12px;
            width: calc(100% - 250px);
            box-sizing: border-box;
        }
    }
}
</style>
